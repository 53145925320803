<template>
  <div id="indexing">
    <div class="mt-12 2xl:px-60 lg:pt-[100px] lg:pb-20">
      <div class="text-center">
        <h1 class="mb-10 text-lg font-bold">
            Our
            <span class="text-primary">Indexing</span>
          </h1>
      </div>
      <div class="justify-items-center grid grid-cols-3 gap-4 lg:grid-cols-9">
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/1garuda.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto mt-0 h-[100px] lg:h-[160px]" src="../assets/indexing/2google.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/3doi.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/4copernicus.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/5road.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/6isjd.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/7sinta.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/8wizdom.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/9oneResearch.png" />
        </div>
        
      </div>
      <div class="justify-items-center grid grid-cols-3 gap-4 lg:grid-cols-8 lg:px-16">
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/10ebsco.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/12clarivate.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/13science.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/14plumx.png" />
        </div>       
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/16dimension.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/17econstor.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/18oaspa.png" />
        </div>
        <div class="rounded-full transition duration-300 ease-in-out hover:bg-primary">
          <img class="mx-auto h-[100px] lg:h-[160px]" src="../assets/indexing/19ezb.png" />
        </div>
      </div>
      </div>
  </div>
</template>
    
    <script>
import "@/assets/tailwind.css";

export default {};
</script>
    
    <style>
</style>