<template>
  <div class="home">
    <div>
      <HomeCatra/>
      <OurJournal/>
      <OurService/>
      <IndexingCatra/>
      <OurExpertise/>
      <TranslatorSchema/>
      <EditingService/>
      <FooterCatra/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import HomeCatra from '@/components/HomeCatra.vue';
import OurJournal from '@/components/OurJournal.vue';
import OurService from '@/components/OurService.vue';
import IndexingCatra from '@/components/IndexingCatra.vue';
import OurExpertise from '@/components/OurExpertise.vue';
import TranslatorSchema from '@/components/TranslatorSchema.vue';
import EditingService from '@/components/EditingService.vue';
import FooterCatra from '@/components/FooterCatra.vue';

export default {
  name: 'HomeView',
  components: {
    HomeCatra,
    OurJournal,
    OurService,
    IndexingCatra,
    OurExpertise,
    TranslatorSchema,
    EditingService,
    FooterCatra
}
}
</script>
